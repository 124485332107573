import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios";
import sha1 from 'crypto-js/sha1';
import router from '../router'
import * as moment from 'moment';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    usuario: '',
    userType: '',
    // db: 'http://localhost/infocafe/api-infocafe/api/',
    // db: "https://beneficiosanantonio.com/api-infocafe/api/",
    // db: "https://benefioelespino.com/api-infocafe/api/",
    // db: "https://aprocae.com/api-infocafe/api/",
    // db: "https://kolibrihn.com/api-infocafe/api/",
    // db: "https://comerciallosamigos.com/api-se2/api/", //santa emilia
    // db: "https://comerciallosamigos.com/api-infocafe/api/",
    // db: "https://infocafehn.com/api-infocafe/api/",
    // db: "https://comerciallospinos.com/api-infocafe/api/",
       db: "https://caviflor.com/api-infocafe/api/",
    // db: "https://beneficiocafeorellana.com/api-infocafe/api/",
    // db: "https://beneficiocruz.com/api-infocafe/api/",
    // db: "https://fincacualbicicleta.com/api-infocafe/api/",
    // db: "https://lempira.comarca-hn.com/api-infocafe/api/",
    // db: "https://infocafehn.com/api-gruporeal/api/",
    // db: "https://bodegaortega.com/api-infocafe/api/",
    headers_db: {
      'Content-Type': 'application/json',
      'Authorization': 'BEARER ' + localStorage.token
    },
    error: '',
    customers: [],
    usuarios: [],
    carga: false,
    snackbars: [],
  },
  mutations: {
    setSnackbar(state, snackbar) {
      snackbar.showing = true;
      snackbar.color = snackbar.color || 'success';
      state.snackbars = state.snackbars.concat(snackbar);
    },
    setHeader(state, token) {
      state.headers_db.Authorization = 'BEARER ' + token;
    },
  },
  actions: {
    ingresoUsuario({
      commit
    }, payload) {
      // alert(sha1(payload.pass).toString());
      axios.post(this.state.db + 'login', {
        user: payload.user,
        pass: sha1(payload.pass).toString()
      })
      .then(async(res) => {
        if (res.data.status == 0) {
          // localStorage.setItem("usuario", payload.user);
          router.push({
            name: 'resetpassword', params: { user: payload.user }
          })
        } 
        // else if (res.data.status == 2) {
        //   let now = new Date();
        //   alert( now );
        //   commit('setSnackbar', {
        //     color: "error",
        //     text: "Su licencia ha expirado"
        //   });
        // } 
        else {
          if (res.data.data.date) {
            let now = moment().format('YYYY/MM/DD');
            let date = moment(res.data.data.date).format('YYYY/MM/DD');
            if (now >= date) {
              commit('setSnackbar', {
                color: "error",
                text: "LICENCIA VENCIDA"
              });
              return;
            }
          }
          let current_user = res.data.data;
          this.state.usuario = current_user.login;
          this.state.id_user = current_user.codigo;
          commit('setHeader', current_user.token);
          localStorage.setItem("token", current_user.token);
          localStorage.setItem("usuario", current_user.login);
          localStorage.setItem("master", current_user.master);
          localStorage.setItem("username", current_user.nombre);
          localStorage.setItem("id_user", current_user.codigo);

          // await axios.get(this.state.db + 'permits/' + current_user.codigo,
          // {
          //   headers: this.state.headers_db
          // }).then(response => {
          //   localStorage.setItem("permisos", JSON.stringify(response.data.data));
          // })
          // .catch((error) => {
          //   commit('setSnackbar', {
          //     color: "error",
          //     text: error
          //   });
          // });

          router.push({
            name: 'Home'
          })
        }
      })
      .catch(err => {
        console.log(err.response.data.message);
        commit('setSnackbar', {
          color: "error",
          text: err.response.data.message
        });
      })
      
    },

    updatePassword({
      commit
    }, payload) {
      axios.post(this.state.db + 'update_password', {
        user: payload.user,
        pass: sha1(payload.password).toString()
      })
      .then(() => {
        router.push({
            name: 'ingreso'
          })
      })
      .catch(err => {
        commit('setSnackbar', {
          color: "error",
          text: "Hay un error en sus credenciales"
        });
        console.log(err.mensaje);
      })
      
    },

    cerrarSesion({
      commit
    }) {
      this.state.usuario = '';
      localStorage.removeItem("usuario");
      localStorage.removeItem("token");
      router.push({
        name: 'ingreso'
      })
    },

    validateSession({ dispatch, commit }, error) {
      console.log(error.response);
      if (error.response) {
          let token = error.response.data.token;
          if (error.response.status == 500) {
            commit('setSnackbar', {
              text: error.response.data.message,
              color:'error'
            });
            return false;
          }

          if (error.response.status == 400) {
            commit('setSnackbar', {
              text: error.response.data.message,
              color:'error'
            });
            return false;
          }
          
          if (token != null) {
            localStorage.token = token;
            commit('setHeader', token);
            return true;
          }
          
          if (error.response.status == 401) {
            commit('setSnackbar', {
              text: 'La sesión ha caducado',
              color:'error'
            });
            dispatch('cerrarSesion');
            return false;
          }
      }
    },

    setSnackbar({
      commit
    }, snackbar) {
      commit('setSnackbar', snackbar);
    },
  },
  modules: {
  }
})
